//POSITION

.position-inherit {
  position: inherit;
}

.position-absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.sticky {
  position: sticky;
}

.bring-to-front {
  position: relative;
  z-index: 99999 !important;
}
//DISPLAY

.display-none {
  display: none;
}

.display-inline {
  display: inline;
}

.display-inline-block {
  display: inline-block;
}

.row {
  display: flex !important;
  margin: 0;
}

.row > *:not(.spinner) {
  width: fit-content;
  margin: 0;
  padding: 0;
}
//FLEX
.flex-row {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.flex-column {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.flex {
  flex: 1;
}

.display-flex {
  display: flex !important;
}
//ALIGNMENT

.ver-align-middle {
  vertical-align: middle;
}

.ver-align-bottom {
  vertical-align: baseline !important;
}

.align-items-center {
  align-items: center;
}

.align-self-center {
  align-self: center;
}
//COLS

.col-1-5 {
  flex: 0 0 12.5%;
  max-width: 12.5%;
}

.col-1-45 {
  flex: 0 0 12.35%;
  max-width: 12.35%;
}

.col-0-5 {
  flex: 0 0 8.2%;
  max-width: 8.2%;
}

.col-10-5 {
  flex: 0 0 87.5%;
  max-width: 87.5%;
}

.col-10-45 {
  flex: 0 0 87.65%;
  max-width: 87.65%;
}

.col-11-5 {
  flex: 0 0 91.8%;
  max-width: 91.8%;
}
@for $i from 1 through 12 {
  .col-lg-#{$i} {
    float: none !important;
  }

  .col-#{$i} {
    padding-left: 0;
    padding-right: 0;
  }

  .col-sm-#{$i} {
    padding-left: 0;
    padding-right: 0;
  }

  .col-md-#{$i} {
    padding-left: 0;
    padding-right: 0;
  }

  .col-lg-#{$i} {
    padding-left: 0;
    padding-right: 0;
  }

  .col-xl-#{$i} {
    padding-left: 0;
    padding-right: 0;
  }
}
@media (min-width: 992px) {
  .col-lg-4 {
    width: 33.33333333% !important;
  }

  .col-lg-8 {
    width: 66.66666667% !important;
  }
}
@media (min-width: 1200px) {
  .col-xl-12 {
    width: 100% !important;
  }
}

.col,
.col-lg,
.col-md,
.col-sm,
.col-xl {
  padding-left: 0;
  padding-right: 0;
}

.stick-to-bottom {
  position: sticky;
  bottom: 0;
}