p {
  margin-bottom: 0;
}

body {
  font-family: $main-font;
  background: $main-background-color;
  font-size: $main-font-size;
  color: $main-font-color;
}

html {
  overflow-y: hidden;
  font-size: 10px !important;
}

h1 {
  color: $main-font-color;
  font-size: 3.5rem;
}

h2 {
  color: $main-font-color;
  font-size: 2.5rem;
  font-family: Segoe UI;
}

h2.clickable:hover {
  color: $basic-button-color;
}

h3 {
  font-family: Segoe UI Semibold;
}

h4 {
  font-family: Segoe UI Semibold;
}
//Label

Label {
  font: $font-label;
  color: $color-label;
}

.label {
  border-radius: 0;
  background-color: $basic-button-color;
  font: $font-label;
  color: $color-label;
}

.label-event {
  background-color: inherit;
  color: rgb(155, 155, 155);
}

.label-info {
  font: $font-info-text;
  color: white;
  display: inline;
  padding: 0.1em 0.3em;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
}

.attribute-label {
  color: $muted-font-color !important;
}

hr {
  margin-top: 0;
  margin-bottom: 0;
}