.notification-read-small {
}

.notification-not-read-small {
  font-weight: bold;
}

.notification-read {
  font-size: 14px;
}

.notification-not-read {
  font-weight: bold;
  font-size: 14px;
}

.notification-list{
  list-style: none;
  padding: 0px;

  h2{
    font-family: Segoe UI Light;
  }

  h2, .buttons-row, .notification-list>.fit-with-header>div{
    margin-bottom: 20px !important;
  }

  li{
    background-color: transparent;
    font-family: Segoe UI !important;
    border-top: 1px solid $muted-gray;
    margin: 0px 20px;
    padding: 10px 10px;
  }

  li:last-child{
    border-bottom: 1px solid $muted-gray;
  }

  .notification-not-read{
    font-family: Segoe UI !important;
    font-weight: normal;
  }

  .notification-not-read .notification-title{
    font-family: Segoe UI Semibold !important;
  }

  .notification-title{
    font-size: 14px;
  }

  .muted-title{
    color: #b6b6b6;
  }

  .notification-rest, .notification-rest label{
    font-size: 12px !important;
  }

  li .row{
    margin: 10px 0px 0px 0px;
    align-items: center;
  }

  .notification-focused{
    background-color: #F0F9FF;
  }

}

.notification-info{
  background-color: white;
}

.notification-info h3{
  color: $basic-button-color;
  font-weight: normal;
  font-family: Segoe UI;
  margin-top: 10px;
}

.notification-info hr {
  margin: 10px 0px;
}

.notification-info .notification-body{
  line-height: 1em;
}
