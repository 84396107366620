.table > tbody > tr:hover {
  //background-color: ; //#f4f8fb;
}

th {
  font-size: 12px;
  font-weight: 500;
}
/*th:nth-child(2){
font-size: 14px;
font-family: Segoe UI Semibold;
color: #333;
}*/
th:last-child {
  margin-left: auto;
}

td {
  color: $contrasting-font-color;
}

.table:not(.project-settings)>thead>tr>th {
  padding-left: 0px !important;
}

.table.project-settings>thead>tr>th {
  font-size: 14px;
  border-top: 1px solid #ddd !important;
  border-bottom: 1px solid #ddd !important;
}

.table.project-settings>tbody>tr>td {
  font-size: 14px;
  border-top: 1px solid #ddd !important;
  border-bottom: 1px solid #ddd !important;
}

.tr-no-lines > th {
  border-bottom: 0px solid $table-lines !important;
}
.tr-no-lines > td {
  border-top: 0px solid $table-lines !important;
}
.tr-no-lines {
  border: 0px !important;
}

.table > tbody > tr > td,
.table > tbody > tr > th,
.table > tfoot > tr > td,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > thead > tr > th {
  //    border-top: 0px solid #ddd;
}

.table > tbody > tr.active > td,
.table > tbody > tr.active > th,
.table > tbody > tr > td.active,
.table > tbody > tr > th.active,
.table > tfoot > tr.active > td,
.table > tfoot > tr.active > th,
.table > tfoot > tr > td.active,
.table > tfoot > tr > th.active,
.table > thead > tr.active > td,
.table > thead > tr.active > th,
.table > thead > tr > td.active,
.table > thead > tr > th.active {
  background-color: $sidebar-item-color;
}

.table-hover > tbody > tr.active:hover > td,
.table-hover > tbody > tr.active:hover > th,
.table-hover > tbody > tr:hover > .active,
.table-hover > tbody > tr > td.active:hover,
.table-hover > tbody > tr > th.active:hover {
  background-color: $sidebar-item-color;
}

.row-highlight {
  tbody tr:hover{
    background-color: $sidebar-item-color;
  }
}

.table-checkbox {
  padding-top: 15px !important;
  padding-right: 0px;
}

.table-highlight-background {
  background-color: rgb(248, 251, 254);
}
