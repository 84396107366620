.task-edit-buttons button i {
    font-size: 25px;
}

.task-info,
.task-info-add {
    font: $font-info-text;
    color: $color-info-text;
    margin-bottom: 0;
    text-align: end;
}

.task-info > .react-datepicker-wrapper {
    //width: 43% !important;
}

.task-info
    > .react-datepicker-wrapper
    > .react-datepicker__input-container
    > input {
    padding-right: 0;
    padding-left: 5px;
    font: $font-info-text-bold;
    color: $color-info-text;
}

.task-list-commandbar {
    display: flex;
    padding: 0 1px;
    height: $task-list-commandbar-height;
    white-space: pre-wrap;
    background-color: $content-background-color; //#f4f8fb;
    border-bottom: $main-border;
}

.task-list-title {
    display: inline;
    -webkit-box-decoration-break: clone;
}

.task-list {
    width: 100%;
}

.task-container {
    .search {
        width: 50% !important;
    }
}

.task-container {
    .modal-content {
        background-color: $form-background-color;
        width: 1200px;
    }
}

.task-add-container {
    width: 1200px !important;

    .modal-content {
        background-color: $form-background-color;
        padding: 0;

        .modal-body {
            padding: 0;
        }
    }
}

.task-add-layout {
    height: $header-height;
    padding-left: 20px;
    background-color: #f4f8fb;
    margin: 0;
    width: 100% !important;
    border-bottom: $main-border;
}

.task-add-layout-button {
    margin-top: calc(
        (#{$header-height} - 30px) / 2
    ) !important;
}

.task-add-layout-2 {
    height: 70px;
    padding-left: $difference-form;
    background-color: transparent; //#f4f8fb;
    margin: 0;
    width: 100% !important;
    border-bottom: $main-border;
}

.task-add-date-picker-placeholder {
    ::placeholder {
        color: red;
    }
}

.task-container-col {
    background-color: #fff;
}

.task-container {
    .table {
        background-color: transparent; //white;
        margin-left: 30px;
        margin-right: 30px;
        width: calc(100% - 60px) !important; //0
    }

    .table > thead {
        font-family: $main-font;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        color: #333;
        border: none;
    }

    .table > tbody > tr {
        border-bottom: 1px solid $content-background-color;
    }
    /*
  .table > tbody > tr:last-child,
  .table > tbody > tr:first-child {
    border-bottom: none;
  }*/
    .statistics > .table > tbody > tr {
        border-bottom: none;
    }

    .table > thead > tr > th {
        border: none;
        //border-color: #F9F9F9 !important;
    }

    .table > tbody > tr:first-child > th:not(last-child) {
        padding: 4px 10px 10px 0 !important;
        background-color: transparent;
        font-family: $main-font;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 19px;
        color: #989898;
        border: none;

        .checkbox-mark {
            border: 0;
        }
    }

    .table > tbody > tr:first-child > th:last-child {
        padding: 4px 0 10px 0 !important;
    }

    .table > tbody > tr > th {
        padding: 4px 10px 10px 0 !important;
        background-color: #fff;
        font-family: $main-font;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 19px;
        color: #989898;
        border: none;
        /*border-bottom: 5px solid #F9F9F9 !important;
    border-right: 5px solid #F9F9F9;*/
        //  border-bottom: 1px solid #bdbdbd !important;
    }

    .table > tbody > tr > th:last-child {
        border-right: 0;
        //  border-bottom: 1px solid #bdbdbd !important;
    }

    .table > tbody > tr > th > .hidden-input {
        border: 0 !important;
        background-color: inherit;
    }

    .table > tbody > tr > td {
        background-color: white;
        font-family: $main-font;
        font-size: 14px;
        color: #767676 !important;
        border: none;
        vertical-align: middle;
    }

    .table > tbody > tr > td.title {
        background-color: white;
        font-family: $main-font;
        font-size: 14px;
        color: #333 !important;
    }
}

.taskCol {
    background-color: #ffffff;
    min-height: 40px;
    border-top: 0;
    border-bottom: 1px solid #0000001a;
    margin: 30px;
    box-shadow: 2px 2px 5px 0 #d4cece;
}

.taskCol:first-of-type {
    margin-top: 0;
}

.taskCol:last-of-type {
    margin-bottom: 0;
}

.taskCol:hover {
    background-color: #f4f8fb;
}

.taskCol li {
    padding: 20px;
}

.taskCol-title {
    font: $font-dnd-task-title;
    margin-bottom: 20px;
}

.taskCol-body {
    margin-top: 10px;
    font-size: 12px;
    line-height: 25px;
}

.taskCol-body > p {
    margin-bottom: 0;
}

.taskCol-tags {
    min-height: 21px;
}

.taskCol .checkbox {
    margin-left: 20px;
    margin-top: 5px;
}

.task-title-input {
    font-size: 2.5rem;
    line-height: 1.5;
    color: $main-font-color;
    box-shadow: none;
    flex: 1;
    font-family: Segoe UI;
    border: $main-border !important;
    margin-left: 0;
}

.task-title-input-add {
    font-size: 2.5rem;
    line-height: 1.2;
    color: $main-font-color;
    box-shadow: none;
    margin-left: 10px;
    border: 2px solid red !important;
}

.task-title-input-add:focus {
    border: 2px solid red !important;
}

.task-title-input-2 {
    border: none;
    flex: 1;
}

.task-add-right-half {
    width: 220px;
    padding-left: 20px;
    height: 600px;
}

.task-add-left-half {
    flex: 1;
    padding-right: 20px;
    border-right: 1px solid $content-background-color;
}

.task-edit-align-select-labels {
    label {
        padding-left: 8px;
    }
}

.task-edit-width {
    min-width: 1000px;
}

.task-edit-right {
    flex: auto;
    min-width: 200px;
    max-width: 300px;
    margin: 0;
    padding: 20px 20px 20px 13px !important;
    background-color: $content-background-color;
    border-left: $main-border;
}

.task-edit-right-columns {
    flex: auto;
    max-width: 200px;
    margin: 0 0 0 10px;
    padding: 20px 20px 20px 13px;
    background-color: $content-background-color;
}

.task-edit-left {
    background-color: $content-background-color;
    position: relative;
    max-width: 1620px;
    min-width: 400px;
    width: calc(
        100% - 300px
    ) !important; //  min-height: calc(100vh - 110px) !important;
}

.task-edit-left-rest {
    padding: calc(#{$odsadenie-form} - #{$difference-form})
        calc(#{$odsadenie-form} - #{$difference-form}) 0
        calc(#{$odsadenie-form} - #{$difference-form}) !important; //  min-height: calc(100vh - 110px) !important;
}

.task-edit-left-columns {
    background-color: white;
    width: calc(100% - 270px) !important;
}

.task-edit-buttons {
    /*position: absolute;
  bottom: 10px;
  width: calc(100% - ( 2 *  #{$odsadenie-form} ) + #{$difference-form} )  !important;*/
    //  padding: $odsadenie-form;
    // #f9f9f9;
    background-color: transparent;
}

.task-edit-buttons-columns {
    /*position: absolute;
  bottom: 10px;
  width: 100%;*/
}

.task-edit-popis {
    min-height: 30px;
    font: $font-value;
    color: $color-value;
    background-color: #f9f9f9 !important;
    padding: 15px;
}

.task-edit-popis:last-child {
    padding-top: 0;
}

.task-edit-popis > div > p {
    margin-bottom: 0;
}

.form-section.task-edit-buttons {
    position: absolute;
    bottom: 30px;

    .pull-right {
        width: 500px;
        position: absolute;
        left: 350px;
    }
}

.repeat-form-container {
    @extend .task-add-container;

    .modal-body {
        background-color: #eaeaea;
    }
}

.vykazyTable {
    background-color: inherit;

    input:not(.hidden-input) {
        border: 1px solid #c4c4c4;
    }

    .add-buttons {
        margin-top: 8px;
        margin-left: 10px;
    }

    .add-title {
        font-family: $main-font-bold;
    }

    .vykazy-approved {
        margin-top: calc(25px - 17px);
    }
    /*   .react-switch-handle{
    height: 10px;
    width: 10px;
    transform: translateX(15px);
    top: 5px;
    left: 5px;
  }*/
}

.vykazyTable > .table {
    font-size: 12px;
    width: calc(100% - #{$difference-form}) !important;
    margin-bottom: 0 !important;
}

.vykazyTableNav {
    font-family: $main-font-color;
    height: 28px;
    font-size: 14px;
}

.vykazyTableNav.active {
    font-family: Segoe UI Semibold;
    height: 208x !important;
    color: #333 !important;
    font-weight: 600;
    border-bottom: 1px solid $basic-button-color !important;
    background-color: transparent !important;
}

.vykazyTable > .table > thead {
    width: 100%;
}

.vykazyTable > .table > thead > tr > th {
    padding-left: 8px;
    padding-right: 8px;
    border-bottom-width: 0;
    //border-bottom: 1px solid #dee2e6;
}

.vykazyTable > .table > thead > tr > th:first-child {
    padding-left: 0;
}

.vykazyTable > .table > tbody > tr > td {
    padding-left: 0;
    padding-right: 0;
    //  border-top: 1px solid #c4c4c4;
}

.vykazyTable > .table > tbody > tr > td:nth-child(2) {
    padding-right: 0;
}

.vykazyTable > .table > tbody > tr:last-child > td {
    padding-left: 0;
}

.vykazyTable input[type='number'] {
    text-align: right;
}

.vykazyTable input::-webkit-inner-spin-button,
.vykazyTable input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.vykazyTable input[type='number'] {
    -moz-appearance: textfield;
}

.attachments {
    background-color: inherit;
    padding: 5px 20px 20px 0;
}

.comment-attachment {
    padding: 2px 5px;
    //  display: inline;
}

.attachment {
    background-color: #f2f1f1;
    display: inline-block;
    height: 30px;
    color: black;
    font-size: 12px;
    padding-left: 3px;
    margin-left: 10px;
}

.attachment-label {
    display: inline-block;
}

.form-section {
    margin-bottom: 15px;

    label:not(.col-form-label):not(.checkbox-mark):not(
            .btn
        ) {
        margin-bottom: 5px;
        margin-left: $difference-form;
    }

    .table {
        width: calc(100% - 20px) !important;
    }

    .form-section-rest {
        margin-left: $difference-form;

        .row.p-r-10 {
            margin-bottom: 10px;
        }
    }
    /*.nav-tabs>.nav-item>.nav-link{
  @extend label:not(.col-form-label);
}*/
}

.form-section:last-child,
.form-section:nth-last-child(2) {
    margin-bottom: 0;
}

.form-selects-entry-column {
    font: $font-label;
    color: $color-label;
    line-height: 16px;
    margin-top: 0;
    margin-bottom: 8px;

    label {
        margin-bottom: 5px;
    }

    .form-selects-entry-column-rest {
        margin-left: 0;
        border: $main-border;

        .disabled-info {
            padding-left: 8px;
        }
    }

    .react-datepicker-wrapper {
        width: 100%;

        .react-datepicker__input-container {
            width: 100%;

            input {
                height: 30px;
                border: none;
                padding-left: 5px;
            }
        }
    }

    .react-datepicker-popper {
        z-index: 9999;
    }
}

.scheduled-in-table {
    width: 320px;
    border: none;
    /*.popover-header,
  h2 {
    padding-right: 20px;
    padding-left: 16px;
    padding-top: 20px;
    padding-bottom: 10px;
    font: $font-label;
    background-color: transparent;
  }
  .popover-body {
    padding: 10px 15px;
  }*/
    .react-datepicker-wrapper {
        width: 100%;
    }
}

.scheduled {
    @extend .scheduled-in-table;
    background-color: $content-background-color;
}

.form-selects-entries {
    background-color: $content-background-color;
    padding-top: 5px;
    padding-bottom: 5px;

    .col-10-45,
    .col-11,
    .col-11-5,
    .col-9,
    .display-inline-block {
        border: $main-border;
        margin-bottom: 5px;
    }
}

.scheduled-items-container {
    border: $main-border;
    background-color: white;
    min-height: 30px;
    padding: 2px $input-padding;

    div {
        background-color: #f2f1f1;
        padding: 2px;
        font: 85% Segoe UI;
        margin-bottom: 2px;
    }
}
