.calendar-container{
  margin-right: 15px;
  .task-stack{
    width: 300px;
    padding: 0px $page-header-odsadenie;
    h1{
      margin-bottom: 20px;
    }
    .stack-item{
      padding: 5px;
      min-height: 30px;
      margin-bottom: 15px;
      box-shadow: 3px 3px 5px #d9d8d8;
      .attribute-label{
        vertical-align: middle;
      }
    }
  }
  .rbc-calendar{
    min-height: max-content;
    background-color: white;
    flex: 1;
    .rbc-toolbar-label{
      font-size: 2.5rem;
    }
    .rbc-btn-group{
      button{
        background-color: #2c3e50;
        color: white;
        border-color:  #2c3e50;
      }
      button.rbc-active{
        background-color: #1a252f;
        color: white
      }
    }
    .rbc-today{
      background-color: #fffadf;
    }
    .rbc-time-content{
      overflow-y: auto;
    }
  }
}

.calendar {
  width: calc( 100% - 300px )
}
