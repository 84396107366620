$header-height: 40px;
$height-without-header: calc( 100vh - calc(#{$header-height})  );
$sidebar-width: 270px;
$compressed-sidebar-width: 46px;
$sidebar-item-height: 40px;

$inputs-border: 6px solid red;
$input-padding: 8px;
$main-border: 0px solid #c4c4c4;
$secondary-border: 1px solid #c4c4c4;

$main-font: Segoe UI;
$main-font-bold: Segoe UI Semibold;
$main-font-size: 14px;
$button-height: 30px;

$commandbar-height: 70px;
$task-list-commandbar-height: 70px;

$font-link: 14px Segoe UI Semibold;
$color-link: #0078D7;

$font-label: 14px Segoe UI Semibold;
$color-label: #333;

$font-task-description: 15px Segoe UI Semibold;
$font-dnd-task-title: 14px Segoe UI Semibold;
$color-task-description: #333;

$font-value: 14px Segoe UI;
$color-value: #333;

$font-no-value: 14px Segoe UI;
$color-no-value: #777;

$font-no-value-mandatory: 14px Segoe UI;
$color-no-value-mandatory: #E81123;

$font-info-text: 12px Segoe UI;
$font-info-text-bold: 12px Segoe UI Semibold;
$color-info-text: #333;
$header-height: 40px;

$odsadenie-form: 30px;
$difference-form: 10px;

$list-odsadenie: 20px;
$page-header-odsadenie: 30px;

//farby
$main-background-color: white;
$sidebar-background-color: #f2f1f1;
$form-background-color: white;
$form-highlighted-background: #f4f8fb;
$row-highlighted-background: #bcd4e7;
$content-background-color: #eaeaea;

$main-font-color: #3C3C3C;
$muted-font-color: #BDBDBD;
$muted-gray: #d9d8d8;
$color-disabled: #b8b8b8;
$contrasting-font-color: #494949;
$header-color: #004578;
$basic-button-color: #0078D4;
$sidebar-item-color: #DCECF9;
$table-lines: #f1f1f1;
